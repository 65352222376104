import vls from '../assets/VlsCanada.png'
import nbwtf from '../assets/nbwtf.png'
import stmec from '../assets/stmec.png'
import WaterLevel from '../assets/WaterLevel.png'
import anbmt from '../assets/anbmt.png'

export const ProjectList = [
    {
        name: "Virtual Learning Strategy",
        company: "Outreach Productions Inc.",
        description: "Assessment, Examination and Report",
        link:'https://vlscanada.com/',
        image: vls
    },
    {
        name: "Wildlife Trunsfund",
        company: "Outreach Productions Inc.",
        description: "New Brunswick",
        link:'https://grant.5dbcloud.ca/',
        image: nbwtf
    },
    {
        name: "Bingo Booking",
        company: "Outreach Productions Inc.",
        description: "Saint Mary Entertainment Center",
        link:'https://stmec.bingonb.net/',
        image: stmec
    },
    {
        name: "Water Level Monitoring",
        company: "Outreach Productions Inc.",
        description: "Village of Southern Victoria",
        link:'https://sv.5dbcloud.ca/',
        image: WaterLevel
    },
    {
        name: "ANBMT Docs",
        company: "Outreach Productions Inc.",
        description: "Massage Therapist",
        link:'https://portal.anbmt.ca/',
        image: anbmt
    },
    // {
    //     name: "Book Shop",
    //     company: "Rommel Martinez",
    //     description: "Massage Therapist",
    //     link:'',
    //     image: anbmt
    // },
    // {
    //     name: "Portfolio",
    //     company: "Personal",
    //     description: "Refresher",
    //     link:''
    // },
    // {
    //     name: "CRUD",
    //     company: "Personal",
    //     description: "Refresher",
    //     link:''
    // },
    // {
    //     name: "CRUD",
    //     company: "Personal",
    //     description: "Refresher",
    //     link:''
    // }
]
