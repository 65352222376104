import React from 'react'
import '../styles/Footer.css'

const Footer = () => {
  return (
    <>
    <div className='footer'>
        <p>&copy; ROMMEL MARTINEZ 2024</p>
    </div>
    </>
  )
}

export default Footer
