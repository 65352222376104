import CodeIcon from '@mui/icons-material/Code';

export const SkillLists = [
    {
        name: "C#",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: ".Net",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Entity Framework Core",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Asp.Net MVC",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Blazor",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Restful API",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "HTML5",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "CSS3",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "JavaScript",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Ajax",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "JQuery",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: " Django",
        compliment: "Intermediate",
        icon: CodeIcon
    },
    {
        name: "Microsoft SQL Server",
        compliment: "Advanced",
        icon: CodeIcon
    },
    {
        name: "Manage IIS Server",
        compliment: "Intermediate",
        icon: CodeIcon
    },    
    {
        name: "ReactJS",
        compliment: "Intermediate",
        icon: CodeIcon
    },    
    {
        name: "NodeJS",
        compliment: "Intermediate",
        icon: CodeIcon
    }
]
